import React, { memo, ReactNode, useMemo } from 'react';
import ThemeProvider from '@plasma/ui.themes.theme-provider';
import { ThemeOverride } from '@plasma/ui.utils.types';
import * as styled from './api-export.styles';
import themes from './themes';
import useApiExportHook from './api-export.hook';
import TextInput from '@plasma/ui.input.text-input';
import Select from '@plasma/ui.input.select/dist/select';
import Button from '@plasma/ui.general.button';
import DataGrid, { ColumnType } from '@plasma/ui.display.data-grid';
import Modal from '@plasma/ui.layout.modal';
import { ApiMeta } from '../../../models/types/apiMeta';
import StatusIndicator from '../../../components/core/StatusIndicator/StatusIndicator';
import { Icon } from '@plasma/ui.general.icon';
import { ApiExportToolBar } from '../../../components/features/api-export/ToolBar';
import CustomDataGrid, { ColumnType2 } from '../../../components/core/CustomDataGrid/CustomDataGrid';

export interface ApiExportProps {
  children?: ReactNode;
  /*
   * Defines custom className
   */
  className?: string;
  /*
   * Defines component's custom style
   */
  style?: React.CSSProperties;
  // add new properties here...
}

type Props = ApiExportProps & ThemeOverride;

function ApiExport(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useApiExportHook();

  const handleModalConfirm = (id: string, status: boolean) => {
    Modal.confirm({
      title: status ? logic.t('status.activate.title') : logic.t('status.deactivate.title'),
      icon: <Icon style={{ color: status ? 'green' : 'red' }} name="GppMaybeOutlined" />,
      content: status ? logic.t('status.activate.confirm.message') : logic.t('status.deactivate.confirm.message'),
      sizeTheme: size,
      onOk() {
        //    logic.toggleStauts(id, status);
      },
      centered: true,
      okType: 'danger',
      visible: true,
    });
  };

  const columns2: ColumnType2<ApiMeta>[] = [
    {
      title: logic.t('header.namespace'),
      dataIndex: 'namespace',
      key: 'namesapce',
      width:'50%'
    },
    {
      title: logic.t('header.name'),
      dataIndex: 'name',
      key: 'name',
      width:'50%'
    },
  ];

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.ApiExport className={`overview ${className ?? ''}`.trim()} style={style}>
        <div className="mainbar">
          <TextInput
            onChange={(value: React.ChangeEvent<HTMLInputElement>) => logic.filterChange(value)}
            placeholder={logic.t('searchField.placeholder')}
            className="searchInput"
          />
          <ApiExportToolBar className="toolBar" exporting={logic.isDownloading} onDownload={logic.downloadHandler} />
        </div>
        <div className="tableContainer">
          <CustomDataGrid<ApiMeta>
            data={logic.filteredData ?? []}
            columns={columns2}
            checkIdentifier="id"
            groupNames={['namespace']}
            loading={logic.loading}
            checkable
            onCheck={(elements, ids) => {
              logic.setSelectedIds(ids);
            }}
          ></CustomDataGrid>
        </div>
      </styled.ApiExport>
    </ThemeProvider>
  );
}

export default ApiExport
