import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SwaggerInfoRequestBody } from '../models/types/swaggerInfoRequestBody';
import { EndpointDto, EndpointInputDto } from '../models/dtos/EndpointDto';
import { DownstreamService } from '../models/types/downstreamEndpoint';
import { ApiDto } from '../models/dtos/ApiDto';
import { ApiMeta } from '../models/types/apiMeta';
import qs from 'qs';
import { SwaggerUIProps } from 'swagger-ui-react';
import { DownstreamEndpointShort } from '../models/types/downstreamEndpointShort';
import { ApiFabricatorDto } from '../models/dtos/ApiFabricatorDto';
import { ServiceStatus } from '../models/enums/ServiceStatus';

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};
const backendInstance = axios.create();

//@ts-ignore
const baseUrl = window.env.BACKEND_URL;
backendInstance.defaults.baseURL = baseUrl;

backendInstance.interceptors.response.use(async (response) => {
  await sleep(250);
  return response;
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => backendInstance.get<T>(url).then(responseBody),
  post: <T>(url: string, body?: unknown, config?: AxiosRequestConfig<unknown> | undefined) =>
    backendInstance.post<T>(url, body, config).then(responseBody),
  put: <T>(url: string, body?: unknown, config?: AxiosRequestConfig<unknown> | undefined) =>
    backendInstance.put<T>(url, body, config).then(responseBody),
  del: <T>(url: string) => backendInstance.delete<T>(url).then(responseBody),
};

const endpointFabricator = {
  fabricateSwaggerInfo: (input: SwaggerInfoRequestBody) =>
    requests.post<ApiFabricatorDto>(`fabricate/swagger`, input, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};
const endpointGroup = {
  save: (input: EndpointDto) =>
    requests.post(`endpoint-group`, input, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  update: (input: EndpointDto) =>
    requests.put(`endpoint-group`, input, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  delete: (id: string) => requests.del(`endpoint-group/${id}`),
  getCompleteById: (id: string) => requests.get<EndpointInputDto>(`endpoint-group/${id}/complete`),
  getGroupNames: () => requests.get<string[]>(`endpoint-group/groups`),
  getUsedDocumentation: (id: string) => requests.get<object>(`endpoint-group/${id}/documentations/used`),
  getDocumentations:(id:string)=> requests.get<{ version: string,title:string, inUse:boolean,id:string }[]>(`endpoint-group/${id}/documentations`),
  getDocumentById:(id:string)=> requests.get<object>(`endpoint-group/documentations/${id}`),
  deleteDocument: (id: string) => requests.del(`endpoint-group/documentations/${id}`),
  postDocument:(id:string,file:FormData) => requests.post(`endpoint-group/${id}/documentations`, file, {
    headers: {       'Content-Type': 'multipart/form-data',}
  }),
  getStatus:(ids:string[]) => {
    const url = new URL(baseUrl);
    url.pathname = `endpoint-group/status`;
    if(ids)ids.forEach(id=>url.searchParams.append("ids", id));
    return requests.get<{[key:string]:ServiceStatusLog[]}>(url.toString());},
    getEvaluatedStatus:(ids:string[]) => {
      const url = new URL(baseUrl);
      url.pathname = `endpoint-group/status/evaluated`;
      if(ids)ids.forEach(id=>url.searchParams.append("ids", id));
      return requests.get<{[key:string]:ServiceStatus}>(url.toString());}

};
const downStreamEndpoint = {
  getAllComplete: () =>
    requests.get<(DownstreamService & { inUse: boolean; serverStatus: ServiceStatus })[]>('downstream-endpoint'),
  getAllShort: () => requests.get<DownstreamEndpointShort[]>('downstream-endpoint/short'),
  getById: (id: string) => requests.get<DownstreamService>(`downstream-endpoint/${id}`),
  toDownstream: (namespace: string, ids: string[]) =>
    requests.post(`/downstream-endpoint/convert-to-downstream?_namespace=${namespace}`, ids, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};

const upstreamEndpoint = {
  save: (input: ApiDto) =>
    requests.post<ApiDto & { id: string }>(`upstream-endpoint`, input, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  saveRange: (input: ApiDto[]) =>
    requests.post(`upstream-endpoint/range`, input, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  getAllMeta: () => requests.get<ApiMeta[]>('upstream-endpoint/meta'),
  getById: (id: string) => requests.get<ApiDto>(`upstream-endpoint/${id}`),
  deleteById: (id: string) => requests.del(`upstream-endpoint/${id}`),
  update: (input: ApiDto & { id: string }) =>
    requests.put(`upstream-endpoint`, input, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  changeStatus: (id: string, status: boolean) => requests.put(`upstream-endpoint/status?id=${id}&status=${status}`),
  export: (ids: string[]) =>
    backendInstance.get(`upstream-endpoint/export?${qs.stringify({ ids: ids })}`, {
      responseType: 'blob', // Specify the response type as 'blob' to handle binary data
    }),
  import: (data: any) =>
    requests.post(`upstream-endpoint/import`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  deleteByNamespace: (namespace: string) => requests.del(`upstream-endpoint/namespace/${namespace}`),
  getNamespaces: () => requests.get<string[]>(`upstream-endpoint/namespaces`),
  getSwagger: (namespace: string) => requests.get<object>(`upstream-endpoint/swagger/${namespace}`),
};
const agent = { endpointFabricator, endpointGroup, downStreamEndpoint, upstreamEndpoint };

export { agent, backendInstance };
