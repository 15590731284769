import React, { memo } from 'react';
import { ServiceStatus } from '../../../models/enums/ServiceStatus';

export interface StatusIndicatorProps {
  active?: boolean;
  size: number;
  falseVaulue?: string;
  trueVaulue?: string;
  status?:ServiceStatus
}

function StatusIndicator(props: StatusIndicatorProps) {
  let statusColor = props.status ==ServiceStatus.Good?"green":props.status==ServiceStatus.Middle?"orange":props.status==ServiceStatus.Bad?"red":undefined;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          marginRight: '5px',
          borderRadius: '50%',
          width: props.size,
          float: 'left',
          height: props.size,
          backgroundColor:statusColor?statusColor:props.active ? 'green' :  'red'
        }}
      />
      <span>{props.active ? props.trueVaulue ?? 'Active' : props.falseVaulue ?? 'Inactive'}</span>
    </div>
  );
}

export default StatusIndicator
