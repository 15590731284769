import React, { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@plasma/ui.general.icon';
import { ColumnType2, CustomTableProps } from './CustomDataGrid';
import { Checkbox } from 'antd';

function useCustomDataGridHook<T>(props: CustomTableProps<T>) {
  type datatype = T & {
    subElements: T[];
    key: string;
    depth: number;
    isGroup: boolean;
    groupByValue: any;
    originalIndex: number;
    checkDisabled: boolean;
  };
  type sortElement = { name: keyof T; desc: boolean };

  const tableRef = useRef<HTMLTableElement | null>(null);
  const keyseparator = '$$$';
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const updateHeight = () => {
      if (tableRef.current) {
        setHeight(tableRef.current?.querySelector('tr.headerRow')?.clientHeight ?? 0);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const keyGenerator = (groups: (keyof T)[], object: T, prefix?: string) => {
    const prefixString = prefix ? `${keyseparator}${prefix}` : '';
    return groups.map((x) => object[x]).join(keyseparator) + prefixString;
  };
  const defaultExpandBuilder = (data: T[], keys: (keyof T)[]) => {
    if (props.groupNames) {
      const groupIndexes = props.defaultExpandGroups?.map((eg) => props.groupNames?.indexOf(eg)! + 1);
      var kp: string[] = [];
      props.data.forEach((d) => {
        groupIndexes?.forEach((gi) => {
          const groups = props.groupNames?.slice(0, gi);

          var value = keyGenerator(groups ?? [], d);
          if (!kp.includes(value)) kp.push(value);
        });
      });
      return kp;
    }
    return [];
  };

  const [expanded, setExpanded] = useState<string[]>([]);
  const [firstData, setFirstData] = useState(false);
  const [groupSortKey, setGroupSortKey] = useState<sortElement[]>([]);
  const [sortKey, setSortKey] = useState<sortElement>();
  const [page, setPage] = useState(1);
  const [selectKeys, setSelectedkeys] = useState<string[]>([]);


  const groupKeys = props.groupNames ?? [];

  useEffect(() => {
    if (!firstData && props.data.length > 0) {
      setExpanded(defaultExpandBuilder(props.data, props.defaultExpandGroups ?? []));
      changeSelectedKey(props.defaultSelected ?? []);
      setFirstData(true);
    }
  }, [props.data]);

  const sortData = (array: datatype[], name: keyof datatype, sortElements: sortElement[]) => {
    let newData = [...array];
    newData.sort((a, b) => {
      let sorting = name ? sortElements.find((x) => x.name === name) : sortElements[sortElements.length - 1];
      if (sorting) {
        let key = sorting?.name as keyof typeof a;
        if (sorting.desc === true) {
          if (a[key] === null && b[key] === null) return 0; // Both values are null, consider them equal
          if (a[key] === null) return 1; // Null values come after non-null values
          if (b[key] === null) return -1; // Non-null values come before null values
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
        } else if (sorting.desc === false) {
          if (a[key] === null && b[key] === null) return 0; // Both values are null, consider them equal
          if (a[key] === null) return 1; // Null values come before non-null values
          if (b[key] === null) return -1; // Non-null values come after null values
          if (a[key] > b[key]) return -1;
          if (a[key] < b[key]) return 1;
        }
      }
      return 0;
    });
    return newData;
  };

  const chunkArray = (arr: datatype[], size?: number) => {
    if (size)
      return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
        arr.slice(index * size, (index + 1) * size),
      );
    else return [arr];
  };
  const handleGroupSort = (name: keyof T) => {
    let newgk = [...groupSortKey];
    let objectIndex = newgk.findIndex((x) => x.name === name);
    if (objectIndex !== -1) {
      let object = newgk.at(objectIndex!)!;
      if (object.desc) object.desc = !object.desc;
      else newgk.splice(objectIndex, 1);
    } else newgk.push({ name: name, desc: true });
    newgk.sort((a, b) => groupKeys.indexOf(a.name) - groupKeys.indexOf(b.name));
    setGroupSortKey(newgk);
  };
  const handleSortKey = (name: keyof T) => {
    if (sortKey?.name === name) {
      if (!sortKey.desc) setSortKey(undefined);
      else setSortKey({ name, desc: !sortKey.desc });
    } else {
      setSortKey({ name, desc: true });
    }
  };
  const sortI = (name: string) => {
    const element = sortKeys.find((x) => x.name === name);
    if (element) {
      return element.desc ? <Icon name="arrow_drop_up" /> : <Icon name="arrow_drop_down" />;
    } else return <Icon className="hide" name="unfold_more" />;
  };

  const sortKeys = useMemo(() => (sortKey ? [...groupSortKey, sortKey] : groupSortKey), [groupSortKey, sortKey]);

  const columns = useMemo(() => {
    return props.columns.sort((a, b) => {
      const indexA = groupKeys.indexOf(a.dataIndex?.toString()! as keyof T);
      const indexB = groupKeys.indexOf(b.dataIndex?.toString()! as keyof T);
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [props.columns, props.groupNames]);

  const keyedData: datatype[][] = useMemo(() => {
    const newData = props.data.map((d, ii) => ({
      checkDisabled: props.checkDisabled ? props.checkDisabled!(d) : false,
      originalIndex: ii,
      ...d,
      key: keyGenerator(groupKeys, d, ii.toString()),
      depth: keyGenerator(groupKeys, d, ii.toString()).split(keyseparator).length - 1,
      isGroup: false,
    })) as datatype[];
    if (sortKey) return chunkArray(sortData(newData, sortKey.name, [sortKey]), props.pageSize);
    else return chunkArray(newData, props.pageSize);
  }, [props.data, sortKey, groupSortKey, groupKeys, selectKeys]);

  const changeSelectedKey = (keys: string[]) => {

    const extractT = (arr: datatype[]) => {
      return arr.map((item) => {
        const { subElements, key, depth, isGroup, groupByValue, originalIndex, checkDisabled, ...tProps } = item;
        return tProps as T;
      });
    };
    const checkedData = keyedData.flatMap((d) => d).filter((d) => keys.some((k) => k === d.originalIndex.toString()));
    if (props.onCheck) props.onCheck(extractT(checkedData),keys );
    setSelectedkeys(keys);
  };

  const groupElements = useMemo(() => {
    if (groupKeys.some((x) => true)) {
      var array: datatype[] = [];
      keyedData[page - 1]?.forEach((d, ii) => {
        let key = d.key.split(keyseparator);
        groupKeys.forEach((gk, index) => {
          if (!array.some((x) => x.key === key.slice(0, index + 1).join(keyseparator))) {
            let obj = {} as datatype;
            groupKeys.slice(0, index + 1).forEach((gk2) => {
              obj[gk2 as keyof typeof obj] = d[gk2 as keyof typeof d];
            });
            obj.key = key.slice(0, index + 1).join(keyseparator);
            obj.subElements = keyedData[page - 1].filter(
              (d) =>
                d.key
                  .split(keyseparator)
                  .slice(0, index + 1)
                  .join(keyseparator) === key.slice(0, index + 1).join(keyseparator),
            );
            obj.depth = key.slice(0, index + 1).length - 1;
            obj.isGroup = true;
            obj.groupByValue = key.slice(0, index + 1).reverse()[0];
            array.push(obj);
          }
        });
      });
      return array;
    }
    return [];
  }, [keyedData, groupKeys, page, props.groupNames]);

  const checkAll = (data: datatype[], identifier: keyof datatype | 'originalIndex') => {
    var ids = data.map((k, ii) => !k.checkDisabled?String(k[identifier as keyof typeof k]):false).filter(Boolean) as string[]

    var newArray = [...selectKeys].filter((k) => !ids.includes(k));
    changeSelectedKey([...newArray, ...ids]);
  };

  const unCheckAll = (data: datatype[], identifier: keyof datatype | 'originalIndex') => {
    var ids = data.map((k, ii) => !k.checkDisabled?String(k[identifier as keyof typeof k]):false).filter(Boolean) as string[]
    var newArray = [...selectKeys].filter((k) => !ids.includes(k));
    changeSelectedKey([...newArray]);
  };

  const isChecked = (data: T[], identifier: keyof T | 'originalIndex') => {
    var ids = data.map((d) => String(d[identifier as keyof typeof d])).filter(Boolean);
    const allChecked = ids.every((element) => selectKeys.includes(element));
    const someChecked = ids.some((element) => selectKeys.includes(element)) && !allChecked;
    return {
      allChecked: allChecked,
      someChecked: someChecked,
    };
  };

  const columns2 = useMemo(() => {
    const allChecked = selectKeys.length ===keyedData[page-1]?.length;
    const identifier = props.checkIdentifier ?? 'originalIndex';
    // const checked = Checked(props.data, identifier);
    const checkHandler = (data: string[]) => {
      if (allChecked) changeSelectedKey([...selectKeys].filter((k) => !data.some((d) => d === k)));
      else changeSelectedKey([...[...selectKeys].filter((k) => !data.some((d) => d === k)), ...data]);
    };

    const selectColumn = {
      title: (
        <Checkbox
          disabled={keyedData[page - 1]?.every((e) => e['checkDisabled' as keyof typeof e])}
          checked={allChecked}
          indeterminate={selectKeys.length > 0 && !allChecked}
          onClick={() =>
            checkHandler(
              keyedData[page - 1]
                .map((k, ii) => !k.checkDisabled?String(k[identifier as keyof typeof k]):false).filter(Boolean) as string[]
            )

          }
        />
      ),
      width: 30,
      render: (value, row, index) => {
        if (!row.isGroup) {
          const identValue = props.checkIdentifier
            ? String(row[props.checkIdentifier as keyof typeof row])
            : row.originalIndex.toString();

          const isChecked = selectKeys.includes(identValue);

          const checkHandler = () => {
            if (isChecked) unCheckAll([row], identifier);
            else checkAll([row], identifier);
          };
          return (
            <div>
              <Checkbox disabled={row.checkDisabled} checked={isChecked} onClick={() => checkHandler()} />
            </div>
          );
        } else {
          const checked = isChecked(row.subElements, identifier);
          const checkHandler = () => {
            if (!checked.allChecked)
              checkAll(
                (row.subElements as datatype[]).filter((e) => !e.checkDisabled),
                identifier,
              );
            else {
              unCheckAll(
                (row.subElements as datatype[]).filter((e) => !e.checkDisabled),
                identifier,
              );
            }
          };
          return (
            <div>
              <Checkbox
                disabled={row.subElements.every((e) => e['checkDisabled' as keyof typeof e])}
                checked={checked.allChecked}
                indeterminate={checked.someChecked}
                onClick={checkHandler}
              />
            </div>
          );
        }
      },
    } as ColumnType2<T>;
    let oldarray = (props.checkable ? [selectColumn, ...columns] : columns).map((x) => ({
      ...x,
      title: (key: number) => {
        return (
          <th key={key} style={{ width: x.width, minWidth: x.minWidth }} className="columContainer">
            <div className="columnContent" style={{}}>
              <div className="title-span">{x.title}</div>

              <div
                className="sortWrapper"
                style={{ float: 'left' }}
                onClick={() => {
                  if (groupKeys.includes(x.dataIndex as keyof T)) handleGroupSort(x.dataIndex as keyof T);
                  else if (x.dataIndex) handleSortKey(x.dataIndex as keyof T);
                }}
              >
                {x.dataIndex ? sortI(x.dataIndex.toString()) : null}
              </div>
            </div>
          </th>
        );
      },
    }));

    groupKeys.forEach((g, ii) => {
      let index = oldarray.findIndex((x) => x.dataIndex === g);
      const originalRender = oldarray[index].render;
      oldarray[index].render = (value: any, record: datatype) => {
        const Kp = originalRender ? (originalRender(value, record, index) as React.ReactNode) : null;

        const isLevel = record.key.split(keyseparator).length - 1 === ii;
        const isExpanded = expanded.includes(record.key);
        return (
          <>
            {isLevel ? (
              <div
                style={{ whiteSpace: 'nowrap' }}
                className="group-cell"
                onClick={() => {
                  if (!isExpanded) {
                    let newexp: string[] = [...expanded];
                    newexp.push(record.key);
                    setExpanded(newexp);
                  } else {
                    let newexp: string[] = [...expanded];
                    newexp = newexp.filter((x) => !x.includes(record.key));
                    setExpanded(newexp);
                  }
                }}
              >
                <Icon name={isExpanded ? 'expand_more' : 'chevron_right'} />
                {Kp}
                <span>
                  {value} ({record.subElements.length})
                </span>
              </div>
            ) : null}
          </>
        );
      };
    });
    return oldarray;
  }, [expanded, columns, sortKeys, props.groupNames, selectKeys, keyedData]);

  const combinedData = keyedData[page - 1]?.concat(groupElements) ?? [];

  const finaldata = useMemo(() => {
    if (groupKeys.some(() => true)) {
      const firstLevelData = combinedData?.filter((x) => x.key.split(keyseparator).length === 1);
      let tempData: datatype[] = sortData(firstLevelData, groupKeys.at(0)!, sortKeys);
      expanded.sort().forEach((x) => {
        let index = tempData.findIndex((d) => d.key === x) + 1;
        let newData = combinedData?.filter((c, ii) => {
          if (c.key.split(keyseparator).length > 1) {
            var shortkey = c.key.split(keyseparator);
            shortkey.pop();
            return x === shortkey.join(keyseparator);
          }
        });
        tempData.splice(index, 0, ...sortData(newData, groupKeys[x.split(keyseparator).length], sortKeys));
      });
      return tempData;
    }
    return combinedData;
  }, [expanded, combinedData, sortKeys, props.groupNames]);

  return {
    tableRef,
    finaldata,
    columns2,
    height,
    setPage,
    page,
  };
}

export default useCustomDataGridHook;
