import { MenuItem } from '@plasma/ui.application.app-provider';

export const menuItems: MenuItem[] = [
  {
    label: 'APIs',
    icon: 'sensors',
    path: '/apis',
  },
  {
    label: 'Services',
    icon: 'cloud',
    path: '/services',
  },
];
