import React, { ReactNode } from 'react';

import Icon from '@plasma/ui.general.icon';
import { Menu, Popover } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import styled from 'styled-components';

interface Props {
  children?: ReactNode;
  icon?: ReactNode;
  menuItems?: ItemType[];
  open?: boolean;
  setOpen?: any;
}



export default function ServiceMenu(props: Props) {
  return (
    <Popover
    overlayClassName='no-padding-popover'
      onVisibleChange={(e) => {
        if (!e && props.setOpen !== undefined) props.setOpen(undefined);
      }}
      placement="left"
      trigger="click"
      visible={props.open}
      destroyTooltipOnHide
      content={
        <Menu style={{ border: 0 }} selectable={false} items={props.menuItems}>
          {props.children}
        </Menu>
      }
    >
      {props.icon ?? <Icon name="more_vert" />}
    </Popover>
  );
}
