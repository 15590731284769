import { useEffect, useMemo } from 'react';
import { agent } from '../../../../api/agent';
import { dataHooks } from '../../../../hooks/dataHooks';

export default function useServiceStatusModalHook(id?: string) {
  const { data, loading,refetch } = dataHooks.useGetInitialData(
    () => agent.endpointGroup.getStatus(id ? [id] : []),
    false,
    true,
  );
  useEffect(() => {
    const intervalId = setInterval(async () => {
      await refetch();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const data2 = useMemo(() => {
    if (data && id) {
      const serviceData = data[id];
      const successfulCalls = serviceData?.filter((d) => d.successful);
      const unSuccessfullCalls = serviceData?.filter((d) => !d.successful);
      const avgResponseTime = Math.round(
        successfulCalls?.filter((d) => d.successful)
          .reduce((accumulator, currentValue) => accumulator + currentValue.time, 0) / successfulCalls.length,
      );
      return {
        serviceData: serviceData,
        successfulCalls: successfulCalls.length,
        unSuccessfullCalls: unSuccessfullCalls.length,
        avgResponseTime: avgResponseTime,
      };
    }
  }, [data]);
  return { data2, loading };
}
