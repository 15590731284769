// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  height: 100vh;
}

.ant-row.plasma-row {
  margin: 0 !important;
}

.none-active-item.ant-menu-item-active {
  color: unset;
}

.no-padding-popover {
  .ant-popover-inner-content{
    padding:0
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE;EACF;AACF","sourcesContent":["#root {\n  height: 100vh;\n}\n\n.ant-row.plasma-row {\n  margin: 0 !important;\n}\n\n.none-active-item.ant-menu-item-active {\n  color: unset;\n}\n\n.no-padding-popover {\n  .ant-popover-inner-content{\n    padding:0\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
