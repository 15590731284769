import styled from 'styled-components';

export const MonitorModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 50vh;
  min-height: 250px;
  .tableContainer {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
    }
  }
`;
