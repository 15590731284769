import React, { memo, ReactNode } from 'react';

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import Button from '@plasma/ui.general.button';
import useParameterCreatorHook from './parameter-creator.hook';
import * as styled from './parameter-creator.styles';
import themes from './themes';
import List, { ListItem } from '@plasma/ui.display.list';
import TextInput from '@plasma/ui.input.text-input';
import Select from '@plasma/ui.input.select';
import { enumToOptions } from '../../../utils/enumToOptions';
import { ApiParameterArea } from '../../../models/enums/ApiParameterArea';
import ScrollableArea from '@plasma/ui.layout.scrollable-area';
import { Icon } from '@plasma/ui.general.icon';
import Modal from '@plasma/ui.layout.modal';
import EnumModalContent from './EnumModalContent/EnumModalContent';
import DescriptionModalContent from './DescriptionModalContent/DescriptionModalContent';
import { endpointParameter } from '../../../models/types/endpointParameter';
import { ParameterTypes } from '../../../models/enums/ParameterTypes';
import Table, { ColumnsType, ColumnType } from 'antd/lib/table';
import { Checkbox } from 'antd';
import { ConfigProvider } from 'antd';
import NoData from '../../core/NoData/NoData';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import ScrollContainer from '../../core/ScrollContainer/ScrollContainer';
import { v1 as uuidv1 } from 'uuid';

export interface ParameterCreatorProps {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  lock?: boolean;
  onChange?: (data: endpointParameter[]) => void;
  value?: endpointParameter[];
}

type Props = ParameterCreatorProps & ThemeOverride;

function ParameterCreator(props: Props) {
  const { children, className, style, size, themeMode } = props;

  const logic = useParameterCreatorHook(props);
  const column: ColumnsType<any> = [
    {
      title: logic.t('header.name'),
      dataIndex: 'name',
      render: (name: string, record, index) => {
        return (
          <TextInput
            className={`nameField ${props.lock ? 'locked' : ''}`}
            onChange={(e) => {
              if (!props.lock) logic.changeHandler(index, 'name', e.target.value);
            }}
            placeholder="Parameter name"
            value={name}
          />
        );
      },
    },
    {
      title: logic.t('header.position'),
      dataIndex: 'in',
      render: (text, record, index) => {
        return (
          <Select
            placeholder="Position"
            menuItemSelectedIcon={undefined}
            className={`selectField ${props.lock ? 'locked' : ''}`}
            value={text}
            options={enumToOptions(ApiParameterArea)}
            onChange={(value) => {
              logic.changeHandler(index, 'in', value);
            }}
          />
        );
      },
    },
    {
      title: logic.t('header.required'),
      dataIndex: 'required',
      render: (text, record, index) => {
        return (
          <Checkbox
            className={`checkBox ${props.lock ? 'locked' : ''}`}
            checked={text}
            onChange={() => {
              logic.changeHandler(index, 'required', !text);
            }}
          />
        );
      },
    },
    {
      title: logic.t('header.type'),
      dataIndex: 'type',
      render: (text, record, index) => {
        return (
          <Select
            placeholder="Type"
            className={`selectField ${props.lock ? 'locked' : ''}`}
            value={text}
            options={enumToOptions(ParameterTypes)}
            onChange={(value) => {
              logic.changeHandler(index, 'type', value);
            }}
          />
        );
      },
    },
    {
      title: logic.t('header.isArray'),
      dataIndex: 'isArray',
      render: (text, record, index) => {
        return (
          <Checkbox
            className={`checkBox ${props.lock ? 'locked' : ''}`}
            checked={text}
            onChange={() => {
              logic.changeHandler(index, 'isArray', !text);
            }}
          />
        );
      },
    },
    {
      title: '',
      width: '210px',
      render: (text, record, index) => {
        return (
          <div className="action-bar">
            <Button
              disabled={(props.lock && !(record.enum.length > 0)) || record.type !== ParameterTypes.Enum}
              onClick={() => {
                logic.openEnumModal(index);
              }}
              type={record.enum.length > 0 ? 'primary' : 'secondary'}
              title="Enum"
            />
            <Button
              disabled={props.lock && !(record.description.length > 0)}
              onClick={() => {
                logic.openDescrModal(index);
              }}
              type={record.description.length > 0 ? 'primary' : 'secondary'}
              title="Description"
            />
            {!props.lock ? (
              <Button
                onClick={() => {
                  logic.removeHandler(index);
                }}
                type="text"
                icon={<Icon name="remove" />}
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <styled.ParameterCreator className={`parameter-creator ${className ?? ''}`.trim()} style={style}>
        <div className="listContainer">
          <ScrollContainer  className="scrollContainer">
            <Table rowKey={uuidv1()}  pagination={false} columns={column} dataSource={logic.dataSource} />
            {logic.isEmpty ? <div className="no-data-overlay" /> : null}
          </ScrollContainer>
        </div>
        {!props.lock ? <Button onClick={logic.addHandler} title="Add" className="addButton" /> : null}
        <Modal
          visible={logic.enumModal !== undefined ? true : false}
          title="Enum"
          okText="Ok"
          cancelText="Cancel"
          onCancel={logic.closeEnumModal}
          onOk={logic.saveTempEnum}
        >
          <EnumModalContent lock={props.lock} value={logic.tempEnum} changeHandler={logic.tempEnumChangeHandler} />
        </Modal>
        <Modal
          visible={logic.descrModal !== undefined ? true : false}
          title="Description"
          okText="Ok"
          cancelText="Cancel"
          onCancel={logic.closeDescrModal}
          onOk={logic.saveTempDescr}
        >
          <DescriptionModalContent value={logic.tempDescr} changeHandler={logic.tempDescrChangeHandler} />
        </Modal>
      </styled.ParameterCreator>
    </ThemeProvider>
  );
}

export default memo(ParameterCreator) as unknown as typeof ParameterCreator;
