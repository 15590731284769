import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';
import help from './service-overview.help';
import { debounce } from 'lodash';
import { useNavigate } from '@plasma/ui.application.router';
import { DownstreamService } from '../../../models/types/downstreamEndpoint';
import translations from './i18n';
import { dataHooks } from '../../../hooks/dataHooks';
import { agent } from '../../../api/agent';
import { ServiceStatus } from '../../../models/enums/ServiceStatus';

function useOverviewHook() {
  useResourceBundle(translations, undefined, '62981f7a-08a7-43e1-afca-58f0e2abf802');
  useHelpBundle(help);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showStatus, setShowStatus] = useState<[string, string] | undefined>();
  const [serviceMenuOpen, setServiceMenuOpen] = useState<string |undefined>(undefined);
  const [filter, setFilter] = useState('');
  const { data, loading: isLoading } = dataHooks.useGetInitialData<
    (DownstreamService & { inUse: boolean; serverStatus: ServiceStatus })[]
  >(() => agent.downStreamEndpoint.getAllComplete(), false, true);

  const { data: statusDictionary, refetch } = dataHooks.useGetInitialData(
    () => agent.endpointGroup.getEvaluatedStatus([]),
    false,
    true,
  );
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!showStatus) {
        await refetch();
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [showStatus]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const filteredData = useMemo(() => {
    let newArray = [...(data ?? [])];
    const filteredResult =
      filter.length > 0
        ? newArray.filter(
            (x) =>
              x.groupName.toLowerCase().includes(filter.toLowerCase()) ||
              x.serviceName.toLowerCase().includes(filter.toLowerCase()) ||
              x.name.toLowerCase().includes(filter.toLowerCase()),
          )
        : newArray;
    return filteredResult;
  }, [data, filter, statusDictionary]);

  const rangeValues = [
    { value: 24, label: t('last-24') },
    { value: 48, label: t('last-48') },
  ];

  const onFilterChange = debounce((filter: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(filter.target.value);
  }, 300);

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const navigateServiceSwagger = (id: string) => {
    window.open(`/services/swagger/${id}`, '_blank');
  };
  const { loading: isUploading, execute } = dataHooks.useApiCall(null, t('notification.document.upload.success'), null);

  const handleUpload = (id: string, files: FileList | null) => {
    if (files !== null) {
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);
      execute(() => agent.endpointGroup.postDocument(id, formData)).finally(() => {
        fileInputRef.current && (fileInputRef.current.value = '');
      });
    }
  };

  return {
    fileInputRef,
    data,
    t,
    rangeValues,
    isUploading,
    isLoading,
    onFilterChange,
    filteredData,
    handleNavigate,
    navigateServiceSwagger,
    handleUpload,
    showStatus,
    setShowStatus,
    statusDictionary,
    serviceMenuOpen,
    setServiceMenuOpen,
  };
}

export default useOverviewHook;
