import { Radio, Statistic, Tabs } from 'antd';
import useServiceStatusModalHook from './ServiceStatusModal.hook';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import  CustomDataGrid, { ColumnType2 } from '../../../core/CustomDataGrid/CustomDataGrid';
import StatusIndicator from '../../../core/StatusIndicator/StatusIndicator';
import DateTimeView from '@plasma/ui.display.date-time-view'
import { ServiceStatus } from '../../../../models/enums/ServiceStatus';
import ReactECharts from 'echarts-for-react';
import { MonitorModal } from './ServiceStatusModal.styles';

export default function ServiceStatusModal({ id }: { id?: string }) {
  const logic = useServiceStatusModalHook(id);
  const { t } = useTranslation();
  const [interval, setIntervall] = useState(1800);
  const chartData = useMemo(()=>{
    const date = new Date(new Date().getTime() - interval * 1000)
    const series = logic.data2?.serviceData.filter((v)=>new Date(v.date)>=date).map((v)=>([v.date,v.time,v.successful]))
    return {series:series}
  },[logic.data2,interval])
  const columns: ColumnType2<ServiceStatusLog>[] = [
    {
      dataIndex: 'successful',
      title: t('header.successful'),
      width:100,
      key: 'successful',
      render: (value, row) => {
        var status = row.successful? row.time<300?ServiceStatus.Good:ServiceStatus.Middle:ServiceStatus.Bad
        return <StatusIndicator status={status} size={16} falseVaulue="" trueVaulue="" />;
      },
    },
    {
      dataIndex: 'date',
      width:175,
      title: t('header.date'),
      key: 'date',
      render:(value,row)=>{
        return <DateTimeView
        value={value}
        options={{
          year: 'numeric',
          month: '2-digit',
          day:'2-digit',
          hour:'2-digit',
          minute:'2-digit',
          second:'2-digit'
        }}
      />
      }
    },
    {
      dataIndex: 'time',
      width:175,
      title: t('header.response-time'),
      key: 'time',
      render:(value,row)=>{
        return `${value} ms`
      }
    },
    {
      dataIndex: 'message',
      title: t('header.message'),
      key: 'message',
    },
  ];

  const option2 = {
    grid: {
      left: "5%",
      top: 60,
      right: 10,
      bottom: 60,
    },
    title: {
      left: 'left',
      text: 'Service Health Monitor',
    },

    xAxis: {
      nameGap:30,
      nameLocation:"center",
      name:`${t("header.date")}`,
      type: 'time',
      boundaryGap: false,
      animation: false,
    },
    yAxis: {
      animation: false,
      nameGap:50,
      nameLocation:"center",
      name:`${t("header.response-time")} ms`,
      type: 'value',
      boundaryGap: [0, '100%'],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
      }
    },
    series: [
      {
        name:`${t("header.response-time")} ms`,
        type: 'bar',
        symbol: 'none',

        itemStyle: {
          color: function (params: any) {
            if(!params.value[2])
              return 'red'
            else if (params.value[1] > 300 ){
            return 'orange';}
          else
          return 'green';
          },
        },
        areaStyle: {},
        data: chartData.series,
        animation: false
      },
    ],
  };

  return (
    <MonitorModal>

      <div style={{ display: 'flex', gap: 10, width: '100%' }}>
        <Statistic
          title={ t('header.avg-response-time')}
          value={logic.data2?.avgResponseTime ?? ''}
          suffix={logic.data2?.avgResponseTime ? 'ms' : null}
        />
        <Statistic valueStyle={{color:"green"}} title={t('header.successful')} value={logic.data2?.successfulCalls} />
        <Statistic valueStyle={{color:"red"}} title={t('header.unsuccessful')} value={logic.data2?.unSuccessfullCalls} />
      </div>
      <Tabs destroyInactiveTabPane className="tableContainer">
  <Tabs.TabPane  tab="Monitor" key="item-1">
  <Radio.Group
  style={{marginBottom:10}}
        options={options}
        onChange={(e)=>{setIntervall(e.target.value)}}
        value={interval}
        optionType="button"
        buttonStyle="solid"
      />
      <div  style={{height:"100%" }}>
      <ReactECharts style={{height:"100%"}}  option={option2} />
      </div>
  </Tabs.TabPane>
  <Tabs.TabPane  tab="Logs" key="item-2">
      <div style={{height:"100%" }}>
        <CustomDataGrid<ServiceStatusLog>
          data={logic.data2?.serviceData ?? []}
          pageSize={100}
          columns={columns}
          loading={!logic.data2?.serviceData}
        ></CustomDataGrid>
      </div>
  </Tabs.TabPane>
</Tabs>
    </MonitorModal>
  );
}

const options = [
  { label: '1 min', value: 60 },
  { label: '30 min', value:1800  },
  { label: '1 h', value: 3600 },
  { label: '24 h', value: 86400 },
];

